@import 'styles/foundation';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 512px;

  h1 {
    margin-bottom: $px_20;
  }
}
